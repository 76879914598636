import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Alert } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../data/snippets/rn-alert.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Alert: Alert }}
        code={snippet}
        platform="react-native"
        gitHubLink="alert"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="isOpen" types={['bool']}>
            <Text>Determines whether or not the alert is displayed.</Text>
          </PropListItem>

          <PropListItem name="text" types={['string']}>
            <Text>The main content text displayed in the alert.</Text>
          </PropListItem>

          <PropListItem name="actions" types={['arrayOf', 'action']}>
            <Text>
              One or more buttons to be displayed. Each action has the following
              shape:
            </Text>
            <List type="unordered">
              <li>
                <code>type</code> (string) - the type of button
              </li>
              <li>
                <code>label</code> (string) - the text describing the action
              </li>
              <li>
                <code>status</code> (string) - the status of the button
              </li>
              <li>
                <code>testID</code> (string) - a unique string to control this
                action in automated tests
              </li>
              <li>
                <code>actionRef</code> (func) - a func to assign a ref to the
                button
              </li>
              <li>
                <code>onClick</code> (func) - responds to the action being
                clicked. You should set <code>isOpen</code> value to false here.
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this alert in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the alert.
            </Text>
          </PropListItem>

          <PropListItem name="onAfterOpen" types={['func']}>
            <Text>Called when the alert is opened.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
